
import React from 'react';
import BlogPost from '../../components/BlogPost';
import postContent from './terms-and-conditions.html';

export default function TermsAndConditions(){

    const content = {
        name: '',
        date: 'September 14th, 2020',
        title: 'Terms and Conditions',
        content: postContent
    };

    return <BlogPost {...content} />

}
