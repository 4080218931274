
const cards = [
    {
        title: 'Welcome to Studymelon',
        path: '/blog/welcome-to-studymelon',
        img: {
            url: '/pages/blog-1/card-title.png',
            alt: 'Welcome to Studymelon'
        },
        text: 'My name is James, welcome! I\'m a contract developer and digital nomad previously out of London (now more commonly' +
            'living in Thailand, Bali and Vietnam).'
    }
];

export {cards}
