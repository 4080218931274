
import NavMain from '../NavMain';

import React, {useState, useEffect} from 'react';
import BlogHeader from '../BlogHeader';
import StickyFooter from '../StickyFooter';
import './blog-post.less';

export default function BlogPost(props) {

    const [offset, setOffset] = useState(0);

    // store y offset for descendant pages
    useEffect(() => {

        function handleScroll() {
            setOffset(window.pageYOffset);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);

    return [
        <BlogHeader key='blog-header' offset={offset}/>,
        <div key='main' className='content-body blog-post'>
            <header>
                <NavMain offset={offset}/>
            </header>

            <div className='blogpost-heading-container'>
                <h1 className='blog-heading'>{props.title}</h1>
                <h2 className='blogpost-author-date'>{props.name} &bull; {props.date}</h2>
            </div>

            <div className='content-main blog-content' dangerouslySetInnerHTML={{__html: props.content}}></div>

            <StickyFooter/>

        </div>
    ]
}
