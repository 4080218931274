
import NavMain from '../../components/NavMain';

import React, {useState, useEffect} from 'react';
import ParallaxHeader from '../../components/ParallaxHeader';
import StickyFooter from '../../components/StickyFooter';

import './index.less';

export default function Home() {

    const [offset, setOffset] = useState(0);

    // store y offset for descendant pages
    useEffect(() => {

        function handleScroll() {
            setOffset(window.pageYOffset);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);

    return [
        <ParallaxHeader key='parallax-header' offset={offset}/>,
        <div key='main' className='content-body'>
            <header>
                <NavMain offset={offset} />
            </header>
            <h1 className='main-heading'>Remember Everything</h1>
            <div className='content-main'>

                <div className='home-content'>

                    <div className='welcome-blurb'>

                        <h2>
                            Organise, remember and find your information instantly
                            from anywhere
                        </h2>

                        <a className='cta' href='https://app.studymelon.com'>
                            let's get started
                        </a>

                    </div>

                </div>

            </div>

            <StickyFooter/>

        </div>
    ]
}
