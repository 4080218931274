
import React from 'react';
import {Link} from 'react-router-dom';

import './blog-listing-card.less';

export default function BlogListingCard(props){

    return(
        <Link to={props.path} className='blog-listing-card'>
            <div className='image-container'>
                <img className='blog-card-image' src={props.img.url} alt={props.img.alt}/>
            </div>
            <div className='card-text'>
                <h3>{props.title}</h3>
                <div>
                    {props.text}
                </div>
            </div>
        </Link>
    )
}
