
import React from 'react';
import './blog-header.less';


export default function BlogHeader(props){

    return (
        <div key='background-image' className='blog-background-image'></div>
    )

}
