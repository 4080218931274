
import NavMain from '../../components/NavMain';

import React, {useState, useEffect} from 'react';
import BlogHeader from '../../components/BlogHeader';
import StickyFooter from '../../components/StickyFooter';
import BlogListingCard from '../../components/BlogListingCard';

import {cards} from './blogCards';
import './blog-listing.less';

export default function BlogListing() {

    const [offset, setOffset] = useState(0);

    // store y offset for descendant pages
    useEffect(() => {

        function handleScroll() {
            setOffset(window.pageYOffset);
        }

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [offset]);

    return [
        <BlogHeader key='blog-header' offset={offset}/>,
        <div key='main' className='content-body'>
            <header>
                <NavMain offset={offset}/>
            </header>
            <h1 className='blog-listing-heading'>Blog</h1>
            <div className='content-main'>

                <div className='blog-listings'>
                    {cards.map((card, i) => <BlogListingCard key={i} {...card}/>)}
                </div>

            </div>

            <StickyFooter/>

        </div>
    ]
}
