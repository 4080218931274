
import React from 'react';
import {Link} from "react-router-dom";
import './sticky-footer.less';

export default function StickyFooter() {

    return(
        <div className='sticky-footer'>

            <Link to='/terms-and-conditions'>terms & conditions</Link>
            <Link to='/privacy-policy'>privacy policy</Link>

            <a href='mailto:hello@studymelon.com'>
                hello@studymelon.com
            </a>

        </div>
    )

}