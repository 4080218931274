
import React from 'react';
import BlogPost from '../../components/BlogPost';
import postContent from './privacy-policy.html';

export default function PrivacyPolicy(){

    const content = {
        name: '',
        date: 'September 14th, 2020',
        title: 'Privacy Policy',
        content: postContent
    };

    return <BlogPost {...content} />

}
