
import React from 'react';
import './parallax-header.less';

const ParallaxHeader = props => {

    return (
        <div key='background-image' className='header-background-image'></div>
    )

};

export default ParallaxHeader;
