
import React from 'react';
import {render} from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import { createBrowserHistory } from 'history';

import App from './App';

const history = createBrowserHistory();

const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
if (path) {
    history.replace(path);
}

// Make store available to all descendants
render(

    <BrowserRouter>
        <App/>
    </BrowserRouter>

    ,
    document.getElementById('root')
);