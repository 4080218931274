
import React from 'react';
import BlogPost from '../../../components/BlogPost';
import postContent from './welcome-to-studymelon.html';

export default function WelcomeToStudyMelon(){

    const content = {
        name: 'James Drummond',
        date: 'September 14th, 2020',
        title: 'Welcome to Studymelon',
        content: postContent
    };

    return <BlogPost {...content} />

}
